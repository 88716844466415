@import '~antd/dist/antd.css';

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, san-serif;
  font-weight: 400;
  margin: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Add custom styles here */
.TextArea--transparent,
.TextArea--transparent:hover,
.TextArea--transparent:focus,
.TextArea--transparent:active,
.TextArea--transparent > textarea,
.TextArea--transparent > textarea:hover,
.TextArea--transparent > textarea:focus,
.TextArea--transparent > textarea:active {
  background: transparent;
  border: none;
  box-shadow: none;
  resize: none;
}

.Text--markdown {
  overflow-wrap: break-word;
  word-break: break-word;
}

.Text--markdown a {
  text-decoration: underline;
}

.Text--markdown p:not(:first-child) {
  margin-top: 4px;
}

.Text--white {
  color: #fff;
}

.Text--white.ant-typography-secondary {
  color: rgba(250, 250, 250, 0.8);
}

.Text--white > h1,
.Text--white > h2,
.Text--white > h3,
.Text--white > h4,
.Text--white > h5,
.Text--white > h6,
.Text--white a {
  color: #fff;
}

.Button--faded {
  opacity: 0.6;
}

.Button--faded:hover {
  opacity: 1;
}

/* Mimic behavior of antd "text"-type Button */
a.RelatedCustomerConversation--link {
  display: block;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

a.RelatedCustomerConversation--link:hover {
  background: rgba(0, 0, 0, 0.018);
}

/* Replayer */
#SessionPlayer .replayer-wrapper {
  left: auto !important;
  top: auto !important;
}

.replayer-wrapper > iframe {
  border: none;
  border-radius: 4px;
  box-shadow: 0 24px 48px rgba(17, 16, 62, 0.12);
}

/* Wrapper class for antd Upload component style overrides */
.AttachFileButton {
  display: flex;
  flex-direction: column-reverse;
  float: left;
}

.AttachFileButton .ant-upload-list.ant-upload-list-text {
  display: flex;
  flex-wrap: wrap;
  float: right;
}

.AttachFileButton .ant-upload-list-item {
  margin-top: 0px;
  margin-bottom: 4px;
}

.GettingStartedCode .ant-tabs-nav::before {
  border-bottom-color: #d9d9d9;
}

.GettingStartedCode .ant-tabs-nav .ant-tabs-tab {
  border-color: #d9d9d9;
}

.ConversationsSearchInput {
  border-top: 1px solid rgb(240, 240, 240);
  border-right: none;
  border-bottom: none;
  border-left: none;
}

.ConversationsSearchInput:hover,
.ConversationsSearchInput:active,
.ConversationsSearchInput:focus {
  border-color: rgb(240, 240, 240);
}

.ConversationsSearchInput.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 4px;
}

.ConversationsSearchInput .ant-input-search-icon::before {
  border-left: 1px solid rgb(240, 240, 240);
}
