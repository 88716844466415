.StripeElement {
  height: 32px;
  padding: 7px 11px;
  width: 100%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.StripeElement--hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.StripeElement--focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.StripeElement--invalid {
  border-color: #f5222d;
}
